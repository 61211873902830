import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import DashboardLayout from '../../../../layouts/dashboardlayout';
import {
  Button, Card, CardBody, Form, FormGroup, Label, Input, Spinner, Alert, FormFeedback
} from 'reactstrap';
import { requestGetWithKey, requestPutId } from '../../../../helpers/utils';
import { navigate } from 'gatsby-link';
import { useLocation } from '@reach/router';

const EditUser = ({ params }) => {
  const location = useLocation();
  const id = params.userid;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [winery, setWinery] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [wineries, setWineries] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    setLoading(true);
    const getUser = [`api/users/${id}`];
    const getWineries = [`api/wineries`];
    const getGroups = [`api/groups`];

    Promise.all([requestGetWithKey(getUser), requestGetWithKey(getWineries), requestGetWithKey(getGroups)])
      .then(([userResponse, wineriesResponse, groupsResponse]) => {
        const user = userResponse.message;
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);
        setWinery(user.winery.id);
        setPermissions(user.groups.map(group => group.codeName));
        setWineries(wineriesResponse.message || []);
        setGroups(groupsResponse.message || []);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching data:', err);
        setError('Error fetching data. Please try again later.');
        setLoading(false);
      });
  }, [id]);

  const validateForm = () => {
    const errors = {};

    if (!firstName) errors.firstName = 'First Name is required';
    if (!lastName) errors.lastName = 'Last Name is required';
    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email address is invalid';
    }
    if (!winery) errors.winery = 'Winery is required';
    if (permissions.length === 0) errors.permissions = 'At least one permission is required';

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setError('Please fix the validation errors.');
      return;
    }

    setLoading(true);
    setError(null);

    const user = {
      firstName,
      lastName,
      email,
      winery,
      groups: permissions
    };

    const updateUser = [`api/users/${id}`, user];

    requestPutId(updateUser)
      .then(response => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          navigate('/admin/users');
        }, 2000);
      })
      .catch(err => {
        console.error('Error updating user:', err);
        setError('Error updating user. Please try again later.');
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Winery Portal - Edit User</title>
      </Helmet>
      <DashboardLayout>
        <Card className="border-bottom mb-3">
          <CardBody className="p-lg-4 card-inside" style={{ height: 'auto' }}>
            <h1 className="font-weight-semi-bold" style={{ fontSize: '20px', color: 'rgb(19, 19, 20)' }}>Edit User</h1>
            {error && <Alert color="danger">{error}</Alert>}
            {success && <Alert color="success">User updated successfully. Redirecting...</Alert>}
            {loading ? (
              <div className="text-center pt-6">
                <Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#77b204' }} />
              </div>
            ) : (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="firstName">First Name <span style={{ color: 'red' }}>*</span></Label>
                  <Input
                    type="text"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    invalid={!!validationErrors.firstName}
                  />
                  <FormFeedback>{validationErrors.firstName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="lastName">Last Name <span style={{ color: 'red' }}>*</span></Label>
                  <Input
                    type="text"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    invalid={!!validationErrors.lastName}
                  />
                  <FormFeedback>{validationErrors.lastName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email <span style={{ color: 'red' }}>*</span></Label>
                  <Input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    invalid={!!validationErrors.email}
                  />
                  <FormFeedback>{validationErrors.email}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="winery">Winery <span style={{ color: 'red' }}>*</span></Label>
                  <Input
                    type="select"
                    id="winery"
                    value={winery}
                    onChange={(e) => setWinery(e.target.value)}
                    invalid={!!validationErrors.winery}
                  >
                    <option value="">Select Winery</option>
                    {wineries.map(winery => (
                      <option key={winery.id} value={winery.id}>{winery.name}</option>
                    ))}
                  </Input>
                  <FormFeedback>{validationErrors.winery}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="permissions">Permissions <span style={{ color: 'red' }}>*</span></Label>
                  <Input
                    type="select"
                    id="permissions"
                    multiple
                    value={permissions}
                    onChange={(e) => setPermissions([...e.target.selectedOptions].map(option => option.value))}
                    invalid={!!validationErrors.permissions}
                  >
                    {groups.map(group => (
                      <option key={group.codeName} value={group.codeName}>{group.name}</option>
                    ))}
                  </Input>
                  <FormFeedback>{validationErrors.permissions}</FormFeedback>
                </FormGroup>
                <Button type="submit" color="primary">Update User</Button>
              </Form>
            )}
          </CardBody>
        </Card>
      </DashboardLayout>
    </>
  );
};

export default EditUser;
